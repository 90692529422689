<template>
  <div>
    <vue-google-autocomplete
      :key="component"
      ref="ac"
      :id="component"
      :classname="classname"
      placeholder="Address"
      v-on:placechanged="getAddressData"
      @hook:mounted="setDefaultAddress"
     :types="addressArray"
    ></vue-google-autocomplete>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
export default {
  name: "AddressAutoComplete",
  props: {
    
    component: {
      type: String,
      default: "map",
    },
    classname: {
      type: String,
      default: "form-control rounded-pill px-5 border-2",
    },

    defaultAutoCompleteText: {
      type: String,
    },
  },

  components: {
    VueGoogleAutocomplete,
  },

  data() {
    return {
      addressArray: ['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'permanently_closed', 'photo', 'place_id', 'scope', 'type', 'url', 'utc_offset', 'vicinity'],
      address: null,
    };
  },

  watch: {
    defaultAutoCompleteText: {
      handler(newValue, oldValue) {
        if (newValue) {
          if (this.$refs?.ac)
            this.$refs.ac.autocompleteText = this.defaultAutoCompleteText;
          this.address = this.defaultAutoCompleteText;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    getAddressData: function (addressData, placeResultData, id) {
      this.$emit("update:addressData", {
        addressData: addressData,
        placeResultData: placeResultData,
      });
    },

    setDefaultAddress() {
      this.$refs.ac.autocompleteText = this.defaultAutoCompleteText;
      this.address = this.defaultAutoCompleteText;
    },
  },
};
</script>

<style scoped>
</style>
